<template>
    <div class="p-4 mb-4 rounded-lg mt-2" :class="divClass" role="alert">
        <div v-if="title != ''" class="text-lg font-medium ml-1">
            <span v-html="title"></span>
        </div>
        <div class="text-sm">
            <ExclamationTriangleIcon v-if="alert == 'danger'" class="h-5 w-5 mr-2 inline-block" />
            <CheckCircleIcon v-if="alert == 'success'" class="h-5 w-5 mr-2 inline-block" />
            <InformationCircleIcon v-if="alert == 'info' || alert == 'dark'" class="h-5 w-5 mr-2 inline-block" />
            <ShieldExclamationIcon v-if="alert == 'warning'" class="h-5 w-5 mr-2 inline-block" />

            <span class="font-medium" v-html="message"></span>
            <ul v-if="list.length" class="mt-1.5 ml-4 list-disc list-inside">
                <li v-for="(text, i) in list" :key="i">
                    <span v-html="text"></span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup>
import { computed } from "vue";
import { ExclamationTriangleIcon, CheckCircleIcon, InformationCircleIcon, ShieldExclamationIcon } from "@heroicons/vue/24/outline";

const props = defineProps({
    alert: { type: String, default: "" },
    title: { type: String, default: "" },
    message: { type: String, default: "" },
    list: { type: Array, default: [] },
});

const divClass = computed(() => {
    return {
        "text-blue-700": props.alert == "info",
        "text-green-700": props.alert == "success",
        "text-red-700": props.alert == "danger",
        "text-yellow-700": props.alert == "warning",
        "text-gray-700": props.alert == "dark",
        "bg-blue-100": props.alert == "info",
        "bg-green-100": props.alert == "success",
        "bg-red-100": props.alert == "danger",
        "bg-yellow-100": props.alert == "warning",
        "bg-gray-100": props.alert == "dark",
    };
});
</script>
